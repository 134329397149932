$(document).foundation();

window.TATC = {};
var map;

// Window On Load
$(window).load(function() {
	TATC.init = $.extend({
		searchBar: function() {
			var menuButton = $('#main-menu').find('.button');
      var searchBar = $('#search-bar');
      var searchBtn = $('.search-btn');
      var searchOverlay = $('#search-overlay');
      
      if (Foundation.MediaQuery.atLeast('large')) {
          searchBar.css('right', menuButton.outerWidth()+18);
      } else {
          searchBar.css('right', '0');
      }

      searchBtn.on('click', function() {
          searchBar.foundation('toggle');
          searchOverlay.foundation('toggle');
      });

      searchOverlay.on('click', function() {
          searchBar.foundation('toggle');
          searchOverlay.foundation('toggle');
      });
		},
		locations: function() {
			if( $('#googlemap').length ) {
				var button = $('#locations .column').find('.button');
				var map_container = $('#google_map_container');

				button.on('click', function(e) {
					e.preventDefault();
					$('#locations .column').removeClass('active');
					$(this).closest('li').addClass('active');
					map_container.html('');
					map_container.append('<div class="marker" data-lat='+$(this).attr('data-lat')+' data-lng='+$(this).attr('data-lng')+'></div>');
					TATC.googleMap.gmap_new_map(map_container);
					$('html, body').animate({
			      scrollTop: map_container.offset().top
			    }, 500);
				});

				if( button.closest('column').hasClass('active') ) {
					var active_button = button.closest('column.active').find('.button');
					map_container.html('');
					map_container.append('<div class="marker" data-lat='+active_button.attr('data-lat')+' data-lng='+active_button.attr('data-lng')+'></div>');
					TATC.googleMap.gmap_new_map(map_container);
					return
				} 
				TATC.googleMap.gmap_new_map(map_container);
			}
		}
	});

	TATC.googleMap = $.extend({
		gmap_new_map: function( $el) {
			var $markers = $el.find('.marker');
			var $mapType = $el.data('map_type_id') != undefined ? $el.data('map_type_id') : google.maps.MapTypeId.ROADMAP;
			var args = {
				zoom: 16,
				center: new google.maps.LatLng(0, 0),
				scrollwheel: false,
		    navigationControl: false,
		    mapTypeControl: false,
		    scaleControl: false,
		    draggable: false,
				mapTypeId	: $mapType
			};
				
			map = new google.maps.Map( $el[0], args);
			
			// add a markers reference
			map.markers = [];
			
			// add markers
			$markers.each(function(){
		    TATC.googleMap.gmap_add_marker( $(this), map);
			});
			
			// center map
			TATC.googleMap.gmap_center_map( map );
			return map;	
		},

		gmap_add_marker: function( $marker, map ) {
			var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
			var marker = new google.maps.Marker({
				position: latlng,
				map: map,
				// icon: templateDir+'/assets/images/gmap-pin.png'
			});

			// add to array
			map.markers.push( marker );

			// if marker contains HTML, add it to an infoWindow
			if( $marker.html() )
			{
				// create info window
				var infowindow = new google.maps.InfoWindow({
					content: $marker.html()
				});

				// show info window when marker is clicked
				google.maps.event.addListener(marker, 'click', function() {
					infowindow.open( map, marker );
				});
			}
		},

		gmap_center_map: function( map ) {
			var bounds = new google.maps.LatLngBounds();

			// loop through all markers and create bounds
			$.each( map.markers, function( i, marker ){
				var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
				bounds.extend( latlng );
			});

			// only 1 marker?
			if( map.markers.length == 1 ) {
				// set center of map
				map.setCenter( bounds.getCenter() );
			  // map.setZoom( 19 );
			  map.panBy(0,-30);
			} else {
				// fit to bounds
				map.setZoom( 8 );
				// map.fitBounds( bounds );
				map.setCenter( bounds.getCenter() );
				map.panBy(0,-100);
			}
		}
	});

	TATC.init.searchBar();
	TATC.init.locations();
});

$( window ).resize(function() {
  TATC.init.searchBar();
});